import React, {useEffect, useState} from "react";
import Header from "../components/UI/Header";
import {Select, Text} from "../components/UI/FormItems";
import {
  fetchDealDocuments,
  uploadAccountOpeningApplication,
  updateApplicationPointOfSale,
  fetchTerritorialDirections,
  fetchPointsOfSale, fetchSkbVerification
} from '../api'
import SmsVerification from "./SmsVerification";
import {ReactComponent as Print} from "../asssets/icons/print.svg";
import {ReactComponent as Upload} from "../asssets/icons/upload.svg";
import Rejection from "./Rejection";
import {Form, Formik} from "formik";
import EmptyContent from "../components/EmptyContent";

const LoanIssue = ({applicationId, mobilePhone, creditContract, onCallBack}) => {

  const [showSms, setShowSms] = useState(false);
  const [loadError, setLoadError] = useState(null);
  const [showRejection, setShowRejection] = useState(false);
  const [activeBtn, setActiveBtn] = useState(true);
  const [timing, setTiming] = useState(180000);
  const [showApplicationFormUpload, setShowApplicationFormUpload] = useState(false);
  const [showDocumentsIssued, setShowDocumentsIssued] = useState(false);
  const [filesError, setFilesError] = useState(false);
  const [territorialDirections, setTerritorialDirections] = useState([]);
  const [pointsOfSale, setPointsOfSale] = useState([]);
  const [showSelectPOS, setShowSelectPOS] = useState(false);
  const [applicationPatched, setApplicationPatched] = useState(false);
  const [openingAccountError, setOpeningAccountError] = useState(false);

  useEffect(() => {
    async function initialize() {
      await fetchTerritorialDirections().then((data) => setTerritorialDirections(data));
    }

    initialize().then(() => {});
  }, [applicationId, mobilePhone, creditContract]);

  const handleSmsCallback = async () => {
    setShowSms(false);
    onCallBack();
  };

  const uploadFile = async (e) => {

    let fileFormData = new FormData();

    const inputFile = document.getElementById(e.target.id);
    fileFormData.append(e.target.id, inputFile.files[0]);

    setFilesError(false);
    setShowDocumentsIssued(false);

    const patchDocument = await uploadAccountOpeningApplication(applicationId, fileFormData);

    if (!patchDocument) {
      return setFilesError(true);
    } else {
      setShowDocumentsIssued(true);
    }
  };

  const handleDocumentGenerate  = async () => {
    setActiveBtn(false)

    if (!applicationPatched) {
      alert("Укажите точку продаж!");
      setActiveBtn(true);
      return;
    }

    if(loadError) {
      setLoadError(false);
    }

    const skbResult = await fetchSkbVerification(applicationId);

    // if (skbResult === "true") {
    //
    // } else if (skbResult === "false") {
    //   alert("Пожалуйста, подождите, проверка СКБ ещё не пройдена");
    //   setActiveBtn(true);
    //   return;
    // } else {
    //   alert(skbResult);
    //   setOpeningAccountError(skbResult);
    //   return;
    // }

    const result = await fetchDealDocuments(applicationId);

    if(result.error) {
      setOpeningAccountError(result.error);
    } else if (!result) {
      setLoadError(true);
      setShowApplicationFormUpload(false);
    } else {
      setActiveBtn(true);
      setShowApplicationFormUpload(true);
    }
  }

  const handleSelectTerritorialDirection = async (value) => {
    const response = await fetchPointsOfSale(value);
    setPointsOfSale(response);
    setShowSelectPOS(true);
  };

  const handleSelectPointOfSale = async (value) => {
    const result = await updateApplicationPointOfSale(applicationId, value);
    if (result) {
      setApplicationPatched(true);
    }
  };

  function millisToMinutesAndSeconds(millis) {
    let minutes = Math.floor(millis / 60000);
    let seconds = ((millis % 60000) / 1000).toFixed(0);
    if(timing > 0){
      setTimeout(() => {setTiming(timing - 1000)}, 1000)
    } else {
      setTiming(180000);
      setActiveBtn(true);
      setLoadError(false);
    }
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }

  if (openingAccountError) {
    return <EmptyContent header={"Обработка заявки"} message={openingAccountError}/>;
  }

  return (
    <div>
      <Header title="Оформить займ" />
      <fieldset style={{ display: "flex", flexDirection: "column" }}>
        <Formik
            initialValues={{}}
            onSubmit={() => {}}
        >
          {() => <Form>
            <div className="grid-container-50">
              <Text label="Сумма займа" value={creditContract.sum} />
              <Text label="Срок" value={creditContract.creditPaymentPlan.length} />
              <Text label="Ставка" value={creditContract.creditPercent} />
              <Text label="Ежемесячный платеж" value={creditContract.creditPaymentPlan[1]?.paymentSum} />
              <Text label="ПСК" value={creditContract.fullCreditCost} />
            </div>
            <div>
              <Select {...territorialDirections}
                      label="Хаб:"
                      name="territorialDirection"
                      Хаб
                      onChange={(e) => handleSelectTerritorialDirection(e.target.value)}
              >
                <option value={0}>Не выбран</option>
                {territorialDirections.map((territorialDirection) => {
                  return (
                      <option key={territorialDirection.id + '_td'} value={territorialDirection.id}>
                        {territorialDirection.name}
                      </option>
                  );
                })}
              </Select>
              {showSelectPOS && <Select {...pointsOfSale}
                                        label="Точка продаж:"
                                        name="pointOfSale"
                                        Точка продаж
                                        onChange={(e) => handleSelectPointOfSale(e.target.value)}
              >
                <option value={0}>Не выбрана</option>
                {pointsOfSale.map((pointOfSale) => {
                  return (
                      <option key={pointOfSale.id + '_td'} value={pointOfSale.id}>
                        {pointOfSale.shortName}
                      </option>
                  );
                })}
              </Select>}
            </div>
              {loadError ? <p className="lb-error">Счет еще не открыт</p> : <div />}
              {filesError && (
                  <div>
                    <label className="lb-error">
                      Заявление-анкета не прикреплена
                    </label>
                  </div>
              )}
            <div>
              {loadError ? <p className="lb-error">{`Повторная попытка сгенерировать документы возможна через: ${millisToMinutesAndSeconds(timing)}`}</p> : <div/>}
              <div style={{display: 'flex'}}>
                <button
                  className={activeBtn ? "btn" : "not-active-btn"}
                  type="button"
                  onClick={() => activeBtn && handleDocumentGenerate()}
                  style={{ marginTop: "8px" }}>
                  Комплект документов <Print className={"icon"} />
                </button>
                <div style={{marginLeft: "10px"}}>

                  <input
                      type="file"
                      id="accountOpeningApplication"
                      onChange={uploadFile}
                      style={{ display: "none" }}
                  />

                  {showApplicationFormUpload && (
                  <button
                      className="btn"
                      type="button"
                      style={{ marginTop: "8px"}}
                      onClick={() => document.getElementById("accountOpeningApplication").click()}>
                    Загрузить заявление-анкету <Upload className="icon" />
                  </button>)}
                </div>
                {showDocumentsIssued && <div style={{marginLeft: "5px", fontSize: "20px"}}>
                  ✅
                </div>}
              </div>

                <button
                    className="btn"
                    type="text"
                    onClick={() => setShowRejection(true)}
                    value="decline"
                    style={{ marginTop: "8px", width: "10%" }}
                >
                  Отказ
                </button>

                {showDocumentsIssued && (
                  <button
                    className="btn"
                    type="button"
                    onClick={() => setShowSms(true)}
                    style={{ marginLeft: "10px", width: "20%" }}
                  >
                    Документы переданы заемщику
                  </button>
                )}
              </div>
          </Form>}
        </Formik>
      </fieldset>

      {showSms && (
        <SmsVerification
          appId={applicationId}
          onCodeCallback={handleSmsCallback}
          phone={mobilePhone}
          typeId="CONTRACT"
          stage={"CLIENT_REFUSED_LOAN"}
        />
      )}

      {showRejection && <Rejection
          applicationId={applicationId}
          onCallBack={() => setShowRejection(false)}/>}
    </div>
  );
};

export default LoanIssue;
